import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useEffect, memo } from 'react';
import loadable from '@loadable/component';
import Slick from 'react-slick';
import PromotionType from './PromotionType';
import AddToWishlist from './AddToWishlist';
import Video from './Video';
import { getImgCropShopify } from '../../utils/helpers';

const ImageDialog = loadable(() => import('./ImageDialog'));

const ProductImageMobile = (props) => {
  let slider;
  const {
    state: { product, variant, changeVariant },
    isFreeGift,
    _images,
  } = props;

  const [dialog, setDialog] = React.useState({ open: false, image: '', video: '' });
  // const [images, setImages] = React.useState([]);
  const [images, setImages] = React.useState([
    ...(product?.videos || []),
    ...(_images.m_images.filter((img) => !img.altText || img.altText?.indexOf('swatch_') === -1) ||
      []),
  ]);

  useEffect(() => {
    const videos = product?.videos || [];

    const __images =
      _images.m_images.filter((img) => !img.altText || img.altText?.indexOf('swatch_') === -1) ||
      [];

    if (videos.length) {
      videos.forEach((v) => {
        if (v?.index !== undefined) {
          __images.splice(v?.index, 0, v);
        }
      });
    }

    setImages(__images);
  }, [_images]);

  const handleOpenDialog = (data) => {
    setDialog({ open: true, ...data });
  };

  useEffect(() => {
    if (changeVariant && slider && variant.image?.originalSrc) {
      const index = images.findIndex((img) =>
        img.src.includes((variant.image?.originalSrc || '-').split('?')[0])
      );
      index >= 0 && slider.slickGoTo(index);
    }
  }, [variant, changeVariant]);

  const settings = {
    centerMode: false,
    arrows: true,
    dots: false,
    infinite: true,
    swipeToSlide: true,
    lazyLoad: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    initialSlide: 0,
    focusOnSelect: true,
    centerPadding: '5px',
  };

  return (
    <>
      <div className="main__product-view__image">
        <div className="main-view">
          <Slick ref={(ref) => (slider = ref)} {...settings}>
            {images.map((v, i) => {
              if (v?.embedUrl) {
                return (
                  <div key={i} className="fgc-main-video">
                    <Video video={v} height={350} />
                  </div>
                );
              }
              return (
                <div
                  key={i}
                  className="fgc-main-img --overlay"
                  onClick={() => handleOpenDialog({ image: v.src })}
                >
                  {v?.gatsbyImageData ? (
                    <GatsbyImage
                      width="420"
                      height="420"
                      loading={i === 0 ? 'eager' : 'lazy'}
                      image={getImage(v)}
                      alt={v?.altText || product.title}
                    />
                  ) : (
                    <img
                      src={getImgCropShopify(v?.src, '420x420')}
                      alt={v?.altText || product.title}
                    />
                  )}
                </div>
              );
            })}
          </Slick>
        </div>
        {!isFreeGift && <AddToWishlist product={product} variant={variant} />}
        <div className="clearfix"></div>
        <div className="more-views">
          <ul>
            <PromotionType product={product} />
          </ul>
        </div>
        {dialog.open && (
          <ImageDialog
            dialog={dialog}
            handleClose={() => setDialog({ open: false, image: '', video: '' })}
            alt={product.title}
          />
        )}
      </div>
    </>
  );
};

export default memo(ProductImageMobile);
