import React, { memo } from 'react';
import { getImgCropShopify } from '../../utils/helpers';

const ListVariants = memo((props) => {
  const { product, setVariant, className, children, images } = props;

  return (
    <div
      className={`main__product-view__${className || 'list-variants'} ${
        className || 'list-variants'
      }`}
    >
      {children}

      <ul className={`${className || 'list-variants'}__list`}>
        {product?.variants?.map((v, i) => {
          const titleVariant =
            v?.selectedOptions?.filter((v) => v.name == 'Color')?.[0]?.value || v?.title;

          let image =
            images.find(
              (img) => img?.altText && img?.altText.trim() === `swatch_${titleVariant}`
            ) || undefined;

          if (!image) {
            image = v.image;
          }

          return (
            <li
              key={i}
              className={`${className || 'list-variants'}__list__item item`}
              onClick={() => setVariant(v)}
            >
              {image && (
                <img
                  className="item__image"
                  title={v.title}
                  width={20}
                  height={20}
                  src={getImgCropShopify(image?.src || image?.originalSrc, '20x20')}
                  alt={image?.altText || v.title}
                />
              )}
              <span className="item__name">{titleVariant}</span>
            </li>
          );
        })}
      </ul>
      <div className="clearfix"></div>
    </div>
  );
});

export default ListVariants;
