import React, { memo } from 'react';
import { getImgCropShopify } from '../../utils/helpers';

const SelectActiveVariant = memo(({ state }) => {
  console.log(state);

  return (
    <>
      {state?.image && (
        <img
          className="item__image"
          width={20}
          height={20}
          src={getImgCropShopify(state?.image?.src || state?.image?.originalSrc, '20x20')}
          alt={state?.image?.altText}
        />
      )}
      <span className="item__name">{state?.titleVariant}</span>
    </>
  );
});

export default SelectActiveVariant;
