import React, { memo, useEffect, useRef, useState } from 'react';
import ListVariants from './ListVariants';
import SelectActiveVariant from './SelectActiveVariant';

const SelectVariants = memo((props) => {
  const box = useRef(null);
  const { setVariant, className, variant, images } = props;
  const [state, setState] = useState();

  useEffect(() => {
    const titleVariant =
      variant?.selectedOptions?.filter((v) => v.name == 'Color')?.[0]?.value || variant?.title;

    setState({
      titleVariant,
      image:
        images.find((img) => img?.altText && img?.altText.trim() === `swatch_${titleVariant}`) ||
        variant.image,
    });
  }, [variant]);

  function toggle(show = true) {
    if (document.getElementsByClassName('select-variants__list').length > 0)
      document.getElementsByClassName('select-variants__list')[0].style.display = show
        ? 'block'
        : 'none';
  }

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        box.current &&
        !box.current.contains(event.target) &&
        document.getElementsByClassName('select-variants__list').length > 0 &&
        document.getElementsByClassName('select-variants__list')[0].offsetParent !== null
      ) {
        toggle(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={box}>
      <ListVariants
        {...props}
        setVariant={(newVariant) => {
          setVariant(newVariant);
          toggle(false);
        }}
      >
        <div
          className={`${className || 'select-variants'}__item-current item`}
          onClick={() => toggle()}
        >
          {state && <SelectActiveVariant state={state} />}
        </div>
      </ListVariants>
    </div>
  );
});

export default SelectVariants;
