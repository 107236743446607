import React from 'react';

const ProductSchema = ({ product, fgcInvetoryProducts, summaryData }) => {
  const { title, description, handle, featuredImage, vendor, options } = product;
  let variants = product.variants;
  const date = new Date();

  if (fgcInvetoryProducts) {
    product.hasOnlyDefaultVariant = fgcInvetoryProducts.hasOnlyDefaultVariant;
    product.hasOutOfStockVariants = fgcInvetoryProducts.hasOutOfStockVariants;

    variants = variants.map((v) => {
      const variant = fgcInvetoryProducts?.variants?.nodes?.find((_v) => _v.id === v.shopifyId);

      if (variant) {
        v.inventoryPolicy = variant.inventoryPolicy;
        v.inventoryQuantity = variant.inventoryQuantity;
      }
      return v;
    });
  }
  return (
    <script id="product-schema" type="application/ld+json">
      {JSON.stringify(
        product.hasOnlyDefaultVariant || variants?.length === 1
          ? {
              '@context': 'http://schema.org/',
              '@type': 'Product',
              image: featuredImage?.originalSrc || '',
              url: `https://www.activeskin.com.au/buy/${handle}`,
              name: title,
              description: description,
              mpn: variants[0].sku,
              sku: variants[0].sku,
              brand: {
                '@type': 'https://schema.org/Brand',
                name: vendor,
              },
              productID: variants[0].id,
              offers: {
                '@type': 'Offer',
                priceCurrency: 'AUD',
                price: variants[0].price,
                priceValidUntil: date?.toLocaleDateString('en-AU'),
                url: `https://www.activeskin.com.au/buy/${handle}`,
                availability: `https://schema.org/${
                  variants[0]?.inventoryPolicy === 'CONTINUE' || variants[0]?.inventoryQuantity > 0
                    ? 'InStock'
                    : 'OutOfStock'
                }`,
                shippingDetails: {
                  '@type': 'OfferShippingDetails',
                  shippingRate: {
                    '@type': 'MonetaryAmount',
                    value: Number(variants[0].price) < 50 ? '4.95' : '0',
                    currency: 'AUD',
                  },
                  shippingDestination: {
                    '@type': 'DefinedRegion',
                    addressCountry: 'AU',
                  },
                  deliveryTime: {
                    '@type': 'ShippingDeliveryTime',
                    handlingTime: {
                      '@type': 'QuantitativeValue',
                      minValue: 0,
                      maxValue: 4,
                      unitCode: 'DAY',
                    },
                    transitTime: {
                      '@type': 'QuantitativeValue',
                      minValue: 1,
                      maxValue: 5,
                      unitCode: 'DAY',
                    },
                  },
                },
                hasMerchantReturnPolicy: {
                  '@type': 'MerchantReturnPolicy',
                  applicableCountry: 'AU',
                  returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
                  merchantReturnDays: 60,
                  returnMethod: 'https://schema.org/ReturnByMail',
                  returnFees: 'https://schema.org/FreeReturn',
                },
              },
              // aggregateRating: {
              //   '@type': 'AggregateRating',
              //   ratingValue: summaryData?.reviewAverageValue || 0,
              //   reviewCount: summaryData?.reviewCount || 0,
              // },
            }
          : [
              {
                '@context': 'https://schema.org/',
                '@type': 'ProductGroup',
                name: title,
                description: description,
                url: `https://www.activeskin.com.au/buy/${handle}`,
                image: featuredImage?.originalSrc || '',
                brand: {
                  '@type': 'Brand',
                  name: vendor,
                },
                //   audience: {
                //     '@type': 'PeopleAudience',
                //     suggestedGender: 'unisex',
                //     suggestedAge: {
                //       '@type': 'QuantitativeValue',
                //       minValue: 13,
                //       unitCode: 'ANN',
                //     },
                //   },
                productGroupID: product.legacyResourceId,
                variesBy: options?.map(({ name }) => {
                  const _name = name?.toLowerCase();
                  return `https://schema.org/${_name === 'colour' ? 'color' : _name}`;
                }),
                hasVariant: variants?.map(
                  ({ sku, legacyResourceId, image, displayName, selectedOptions, ...v }) => {
                    let variant = {
                      '@type': 'Product',
                      sku,
                      mpn: sku,
                      // gtin14: legacyResourceId,
                      image: image?.originalSrc || '',
                      name: v?.title,
                      description: displayName,
                      //   color: 'Green',
                      //   size: 'small',
                      offers: {
                        '@type': 'Offer',
                        url: `https://www.activeskin.com.au/buy/${handle}`,
                        priceCurrency: 'AUD',
                        price: v?.price,
                        priceValidUntil: date?.toLocaleDateString('en-AU'),
                        itemCondition: 'https://schema.org/NewCondition',
                        availability: `https://schema.org/${
                          v?.inventoryPolicy === 'CONTINUE' || v?.inventoryQuantity > 0
                            ? 'InStock'
                            : 'OutOfStock'
                        }`,
                        shippingDetails: { '@id': '#shipping_policy' },
                        hasMerchantReturnPolicy: { '@id': '#return_policy' },
                      },
                    };

                    selectedOptions?.map((option) => {
                      variant = {
                        ...variant,
                        [option.name?.toLowerCase() === 'colour' ? 'Color' : option.name]:
                          option.value,
                      };
                    });

                    return variant;
                  }
                ),
                // aggregateRating: {
                //   '@type': 'AggregateRating',
                //   ratingValue: summaryData?.reviewAverageValue || 0,
                //   reviewCount: summaryData?.reviewCount || 0,
                // },
              },
              {
                '@context': 'https://schema.org/',
                '@type': 'OfferShippingDetails',
                '@id': '#shipping_policy',
                shippingRate: {
                  '@type': 'MonetaryAmount',
                  value: Number(variants[0].price) < 50 ? '4.95' : '0',
                  currency: 'AUD',
                },
                shippingDestination: {
                  '@type': 'DefinedRegion',
                  addressCountry: 'AU',
                },
                deliveryTime: {
                  '@type': 'ShippingDeliveryTime',
                  handlingTime: {
                    '@type': 'QuantitativeValue',
                    minValue: 0,
                    maxValue: 4,
                    unitCode: 'DAY',
                  },
                  transitTime: {
                    '@type': 'QuantitativeValue',
                    minValue: 1,
                    maxValue: 5,
                    unitCode: 'DAY',
                  },
                },
              },
              {
                '@context': 'http://schema.org/',
                '@type': 'MerchantReturnPolicy',
                '@id': '#return_policy',
                applicableCountry: 'AU',
                returnPolicyCategory: 'https://schema.org/MerchantReturnFiniteReturnWindow',
                merchantReturnDays: 60,
                returnMethod: 'https://schema.org/ReturnByMail',
                returnFees: 'https://schema.org/FreeReturn',
              },
            ]
      )}
    </script>
  );
};

export default ProductSchema;
